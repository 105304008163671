<template>
  <touch-card
    class="pb-0"
    @action="goTo"
  >
    <b-row class="pb-0 d-flex">
      <b-col
        v-if="emoji !== null"
        class="card-icon xl"
      >
        {{ emoji }}
      </b-col>
      <b-col
        class="d-flex justify-content-between flex-column ml-50 w-100"
      >
        <div class="d-flex justify-content-between">
          <div class="flex-fill">
            <h6 class="mb-25">
              {{ name }}
            </h6>
            <div>
              <span class="text-primary">{{ numberFormat(totalWeight) }} {{ $t('units.kg') }} </span>
              <span
                class="text-black-50 font-weight-light"
              > / </span>
              <span class="text-primary">{{ numberFormat(quantity) }} {{ $t('units.package') }} </span>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <span>
              <feather-icon
                icon="ChevronRightIcon"
                size="22"
                class="text-muted cursor-pointer"
              />
            </span>
          </div>
        </div>
      </b-col>
    </b-row>
  </touch-card>
</template>

<script>
import TouchCard from '@core/components/touch-card/TouchCard.vue'
import { BCol, BRow } from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
    TouchCard,
  },
  props: {
    goTo: {
      type: Function,
      default: () => {},
    },
    id: {
      type: [Number],
      required: true,
    },
    emoji: {
      type: [String, null],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    totalWeight: {
      type: [Number, String],
      required: true,
    },
    quantity: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      //
    }
  },
  mounted() {
  },
  methods: {
    /**
     * @param {Number|String} v
     * @returns {*}
     */
    numberFormat(v) {
      return window.numberFormat(v)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../@core/scss/base/bootstrap-extended/_include';
@import '../../@core/scss/base/components/_include';

</style>
