<template>
  <scroller
    :on-refresh="refresh"
  >
    <NavButtonBar>
      <nav-button @action="$router.back()">
        <i class="fa-solid fa-angle-left" />
      </nav-button>
    </NavButtonBar>
    <h1 class="text-primary mt-2">
      {{ $t('pages.campaign.title') }}
    </h1>
    <h4
      v-if="campaign !== null"
      class="text-ellipsis"
    >
      {{ campaign.name }}
    </h4>

    <div class="mt-2 mb-2 pb-25">
      <template v-if="loader || campaign === null">
        <div class="d-flex align-items-center justify-content-center my-5 py-2">
          <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
          />
        </div>
      </template>

      <template v-else>
        <div class="pt-75 mb-2 pb-25">
          <div>
            <NavButtonBar class="pt-0 mb-75 d-flex align-items-center">
              <nav-button
                class="mr-75"
                color="primary"
                @action="askEdit"
              >
                <i class="fa-regular fa-pencil" />
              </nav-button>
              <span
                @click="askEdit"
              >{{ $t('therms.edit') }}</span>
            </NavButtonBar>
          </div>

          <div>
            <NavButtonBar class="pt-0 mb-75 d-flex align-items-center">
              <nav-button
                class="mr-75"
                color="danger"
                @action="askDelete"
              >
                <i class="fa-regular fa-trash" />
              </nav-button>
              <span
                @click="askDelete"
              >{{ $t('therms.delete') }}</span>
            </NavButtonBar>
          </div>
        </div>

        <template v-if="error">
          <div
            class="mb-5 text-muted text-center d-flex flex-column px-3 animate__animated animate__fadeIn animate__fast"
          >
            <h1 class="mt-3 mb-2">
              <i class="fa-regular fa-cloud-exclamation" />
            </h1>
            {{ $t('pages.campaign.one.loading-error') }}

            <div>
              <TouchButton
                size="xs"
                class="mt-2"
                @action="()=>{
                  return loadData(true)
                }"
              >
                {{ $t('therms.retry') }}
              </TouchButton>
            </div>
          </div>
        </template>
        <template v-else-if="dataLoader">
          <div class="d-flex align-items-center justify-content-center my-5 py-2">
            <b-spinner
              variant="primary"
              small
              style="width: 3rem; height: 3rem;"
            />
          </div>
        </template>
        <template v-else-if="!dataLoader">
          <ProductionCardResume
            v-for="production in campaign.stats"
            :id="production.id"
            :key="production.id"
            class="mb-2 animate__animated animate__fadeInUp"
            :emoji="production.emoji"
            :name="production.name"
            :go-to="()=>goToOneCrop(production)"
            :total-weight="production.harvest.weight"
            :quantity="production.harvest.quantities"
          />
        </template>
      </template>
    </div>
  </scroller>
</template>

<script>
import NavButtonBar from '@core/layouts/components/app-navbar/NavButtonBar.vue'
import NavButton from '@core/layouts/components/app-navbar/NavButton.vue'
import { Dialog } from '@capacitor/dialog'
import { BSpinner } from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import {
  NAME_RESUME_CAMPAIGNS,
  NAME_RESUME_EDIT_CAMPAIGN,
  NAME_RESUME_ONE_CAMPAIGN_CROP,
} from '@/router/routes/campaigns'
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import ProductionCardResume from '@/views/components/ProductionCardResume.vue'

export default {
  components: {
    NavButtonBar,
    NavButton,
    ProductionCardResume,
    BSpinner,
    TouchButton,
  },
  data() {
    return {
      // UI
      loader: false,
      dataLoader: false,
      error: false,

      // data
      campaign: null,
    }
  },
  computed: {
    /**
     * @returns {boolean}
     */
    isTablet() {
      return window.isTablet()
    },
    /**
     * @returns {[{name: string, id: string, items: *},{name: string, id: string, items: *}]}
     */
    repartition() {
      const totalQt = this.campaign.stats.map(e => e.quantity)
        .reduce((partialSum, a) => partialSum + a, 0)
      const totalWeight = this.campaign.stats.map(e => e.total_weight)
        .reduce((partialSum, a) => partialSum + a, 0)

      return [
        {
          id: 'total_weight',
          name: this.$t('units.kg'),
          items: this.campaign.stats.map(e => ({
            color: e.color,
            label: e.name,
            // eslint-disable-next-line no-mixed-operators
            percent: totalWeight > 0 ? Number((parseFloat(e.total_weight) * 100 / parseFloat(totalWeight)).toFixed(2)) : 0,
          })),
        },
        {
          id: 'quantity',
          name: this.$t('units.package'),
          items: this.campaign.stats.map(e => ({
            color: e.color,
            label: e.name,
            // eslint-disable-next-line no-mixed-operators
            percent: totalQt > 0 ? Number((parseFloat(e.quantity) * 100 / parseFloat(totalQt)).toFixed(2)) : 0,
          })),
        },
      ]
    },
  },
  async mounted() {
    try {
      this.campaign = await (this.$store.dispatch('campaigns/find', this.$router.currentRoute.params.campaign_id))
    } catch (err) {
      await this.$router.replace({ name: NAME_RESUME_CAMPAIGNS })
      throw err
    }

    await this.loadData()
  },
  methods: {
    /**
     * @param {any} element
     */
    goToOneCrop(element) {
      this.$router.push({
        name: NAME_RESUME_ONE_CAMPAIGN_CROP,
        params: {
          campaign_id: this.campaign.id.toString(),
          crop_id: element.id.toString(),
        },
      })
    },
    /**
     * @returns {Promise<Route|null>}
     */
    async askEdit() {
      if (this.campaign === null) return null

      return this.$router.push({
        name: NAME_RESUME_EDIT_CAMPAIGN,
        params: {
          campaign_id: this.campaign.id,
        },
      })
    },
    /**
     * @returns {Promise<void>}
     */
    async askDelete() {
      if (this.campaign === null) return

      const { value } = await Dialog.confirm({
        title: this.$t('therms.deleting').toString(),
        message: this.$t('actions.campaign-delete.message').toString(),
        okButtonTitle: this.$t('therms.confirm').toString(),
        cancelButtonTitle: this.$t('therms.cancel').toString(),
      })

      if (value) {
        this.loader = true
        if (!await this.$store.dispatch('campaigns/delete', this.campaign.id)) {
          await Dialog.alert({
            title: this.$t('therms.deleting').toString(),
            message: this.$t('actions.campaign-delete-error.message').toString(),
            buttonTitle: this.$t('therms.close').toString(),
          })

          this.loader = false
          return
        }
        await this.$router.back()
      }
    },
    /**
     * @param {Function} finishToRefresh
     * @returns {Promise<void>}
     */
    async refresh(finishToRefresh) {
      if (this.loader || this.campaign === null || this.dataLoader) {
        finishToRefresh()
        return
      }

      this.error = false

      try {
        await (this.$store.dispatch('campaigns/fetchStats', {
          id: this.campaign.id,
          force: true,
        }))

        finishToRefresh()
      } catch (err) {
        this.error = true
        finishToRefresh()

        throw err
      }
    },
    /**
     * @param {Boolean} force
     * @returns {Promise<void>}
     */
    async loadData(force = false) {
      try {
        if (this.loader || this.campaign === null) return

        this.error = false
        this.dataLoader = true
        if (force) {
          await this.$store.commit('campaigns/clearStats', this.campaign.id)
        }

        await (this.$store.dispatch('campaigns/fetchStats', {
          id: this.campaign.id,
          force,
        }))
        this.dataLoader = false
      } catch (e) {
        setTimeout(() => {
          this.error = true
        }, 100)
      }
    },
    /**
     * @param {Number|String} v
     * @returns {*}
     */
    numberFormat(v) {
      return window.numberFormat(v)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../@core/scss/base/components/_include';

</style>
